
.project42-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    justify-content: center;
}

.project42 {
    width: 90px;
    height: 90px;
    background-color: white;
    border-radius: 50%;
    color: var(--custom-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 0.6s;
    text-align: center;
}

.project42.completed:hover {
    /* border: 20px solid var(--custom-green); */
    cursor: pointer;
}

.project42.completed {
    border: 8px solid var(--custom-green);
}

.project42.in-progress {
    border: 8px solid var(--custom-blue)
}

.project42.not-started {
    border: 8px solid var(--custom-grey);
}

.levels {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.down-arrow {
    /* border: 1px solid var(--custom-blue); */
}
.down-arrow img {
    width: 50px;
}

.levels {
    font-size: 0.625rem /* 10px */;
}

.levels img{
    width: 16px;
}

.levels-content {

}

.project-summary {
    display: none;
}

.project-summary .tool-tip {
    visibility: hidden;
    width:300px;
    background-color: var(--custom-grey);
    color: #fff;
    padding: 1rem;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}


.project-summary .tool-tip::after {
    content: " ";
    position: absolute;
    /* top: 25%; */
    /*right: 100%;*/ /* To the left of the tooltip */
    bottom: 100%;
    left: 10%;
    transform: rotate(90deg);
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent var(--custom-grey) transparent transparent;
}

.level-6 .project-summary {
    transform: translate(200px, -350px);
}

.level-6 .project-summary .tool-tip::after {
    content: " ";
    position: absolute;
    bottom: 10%;
    left: -10px;
    /* right: 150%; */ /* To the left of the tooltip */
    /* bottom: 100%; */
    transform: rotate(0deg);
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent var(--custom-grey) transparent transparent;
}


.tool-tip div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.status-names {
    display: flex;
    gap: 20px;
    font-size: 0.75rem;
    /* border: 1px solid red; */
}

.status-names div:nth-child(odd) {
    /* background-color: white; */
    /* color: black; */
}

.status-colors {
    /* border: 1px solid green; */
    display: flex;
    justify-content:space-around;
}

.status-colors .status-color {
    height: 20px;
    width: 20px;
    border: 1px solid;
    border-radius: 50%;
}

.status-color:nth-child(1) {
    background-color: var(--custom-green);
}

.status-color:nth-child(2) {
    background-color: var(--custom-blue);

}

.status-color:nth-child(3) {
    background-color: var(--custom-grey);
}


@media screen and (min-width: 760px) {
    .project42 {
        width: 180px;
        height: 180px;
    }

    .down-arrow img {
        width: 70px;
    }

    .levels img{
        width: 32px;
    }

    .levels {
        font-size: 1rem;
    }

    .project-summary {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    .project42.completed:hover {
        border: 40px solid var(--custom-green);
    }

    .project42:hover ~ .project-summary .tool-tip{
        visibility: visible;
    }


}
