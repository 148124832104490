.col p {
    font-weight: 200;
}

h2 {
    text-decoration: underline;
    text-decoration-color: var(--custom-green);
    text-decoration-thickness: 4px;
}

.svg-container {
    width: 14px;
    height: 14px;
    /* border: 1px solid red; */
    /* border-radius: 5px; */
    display: inline-block;
    overflow: hidden;
}

.technologies {
    /* padding: 40px;
     */
    /* align-self: center;
    justify-self: center; */
    margin-top: 50px;
}

.technologies ul {
    display: flex;
    flex-wrap: wrap;
}

.technologies ul li {
    margin-right: 50px;
    list-style-type: circle;
    line-height: 2rem;
}

.orbit {
    width: 200px;
    height: 200px;
    position: relative;
    margin: 100px;
  }
  
  .orbit-line {
    position: absolute;
    /* border: dashed 3px var(--custom-green); */
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  
  .planet {
    display: block;
    width: 50px;
    height: 50px;
    /* background-color: var(--custom-grey); */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    border-radius: 50%;
    animation: rotationCW 5s infinite linear;
  }
  
  .planet1 {
    animation-delay: 0s;
  }
  
  .planet2 {
    animation-delay: 0.5s;
  }
  
  .planet3 {
    animation-delay: 1s;
  }
  
  .planet4 {
    animation-delay: 1.5s;
  }

  .planet5 {
    animation-delay: 2s;
  }

  .planet6 {
    animation-delay: 2.5s;
  }

  .planet7 {
    animation-delay: 3s;
  }

  .planet8 {
    animation-delay: 3.5s;
  }

  .planet9 {
    animation-delay: 4s;
  }

  .planet10 {
    animation-delay: 4.5s;
  }

  .profile-image:has(.card) .card {
    border: none;
    transition: none;
    transform: none;
    height: 500px;

    .card-image { height: 100%; }

    .card-text { display: none; }
  }
  
  @keyframes rotationCW {
    from {
      transform: rotate(0deg) translateX(100px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(100px) rotate(-360deg);
    }
  }
  