
.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    grid-column: span 2;
    gap: 30px;
}

.projects .card {
    width: 350px;
    cursor: pointer;
}

.title-container {
    text-align: center;
}


.titleLine {
    border-top: 2px solid var(--custom-grey);
}

@media screen and (min-width: 760px) {
    .projects {
        grid-column: span 2;
    }

    .title-container {
        display: flex;
        align-items: center;
        gap: 30px;
        width: 100%;
        justify-content: center;
    }

    .titleLine {
        width: 12%;
    }
}

@media screen and (min-width:1600px) {

}