/* 
    General styles
*/

body {
    background-color: var(--custom-black);
    color: var(--custom-white);
}

h1 {
    font-size: 3rem;
}

h2 {
    font-weight: 900;
}

.app-container {
    /* border: 1px solid red; */
    /* height: 100vh; */
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    /* width: 80%; */
    margin: 0 auto;
    row-gap: 40px;
}

header {
    /* border: 1px solid green; */
    position: sticky;
    top: 0;
    background-color: var(--custom-black);
    opacity: 0.9;
    z-index: 10000;

}

main {
    min-height: 80vh;
}

button {
    width: 170px;
    padding: 10px;
    font-weight: 500;
    border: 1px solid var(--custom-green);
    background-color: var(--custom-blue);
    cursor: pointer;
    transition: 0.6s;

}

button:hover {
    background-color: var(--custom-black);
    color: var(--custom-green);
    box-shadow: 4px 4px var(--custom-gold);
    transition: 0.3s;
}

main .col.profile-image{
    display: none;
}

main .col.profile-image .card{
    width: 400px;
}

main .featured-projects {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
}

main .featured-projects .card {
    cursor: pointer;
}

main .col{
    margin-bottom: 80px;
}

footer {
    border-top: 2px solid var(--custom-grey);
    padding: 40px;
}

.footer-container {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

@media screen and (min-width: 760px) {

    .app-container {
        width: 60%;
        row-gap: 100px;
    }

    footer {
        width: 60%;
        margin: 0 auto;
    }

    .footer-container {
        flex-direction: row;
    }

    main {
        /* border: 1px solid orange; */
        display: grid;
        grid-template-columns: 45% 45%;
        grid-auto-rows: minmax(100px, auto);
    }

    main .col {
        margin-bottom: 30px;
    }

    main .col.profile-image{
        display: flex;
        justify-content: flex-end;
    }

    main .featured-projects {
        grid-column: span 2;
        grid-template-columns: 50% 50%;
        justify-content: space-between;
        gap: 50px 30px;
    }
}

@media screen and (min-width: 1600px) {
    .app-container {
        max-width: 1600px;
        padding: 40px 100px;
    }

    main .featured-projects {
        grid-template-columns: repeat(3, 30%);
        gap: 50px 15px;
    }
}