/* 
    Base Styles
*/

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,400;0,600;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --custom-black: rgb(17, 17, 17);
    --custom-white:rgb(248, 248, 248);
    --custom-blue: rgb(137, 225, 251);
    --custom-grey: rgb(52, 52, 52);
    --custom-green: rgb(178, 219, 129);
    --custom-gold: rgb(255, 250, 41);
    --containerHeight: 300px;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Source Code Pro', monospace;
}

body,
body * {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: var(--custom-white)
}
