.card {
    /* border: 5px solid white; */
    display: flex;
    flex-direction: column;
    /* width: 400px; */
    height: 400px;
    /* justify-self: center; */
    background-color: rgb(28, 29, 30);
    /* margin: 0 auto; */
    color: black;
    transition: 0.5s;
    position: relative;
}

.card::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.card:hover::before {
    transition: all 0.5s ease;
}

.card:hover {
    transform: scale(1.04);
    transition: all 0.5s ease;
    border: 10px solid var(--custom-gold);
    border-radius: 5px;
}

.card-image {
    /* height: 80%; */
    height: 75%;
}

.card-image div {
    height: 100%;
}

.card-text {
    font-family: 'Montserrat', sans-serif;
    background-color: var(--custom-white);
    height: 25%;
}

.card-text h3 {
    font-family: 'Montserrat', sans-serif;
}

.col .card,
.col .card-image div{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.profile-image .card::before {
    box-shadow: none;
    transform: none;
}

.profile-image .card {
    height: 600px;
}

.card-text h3 {
    font-size: 1rem;
    padding: 5px;
}

.card-text p {
    /* font-size: 0.9rem; */
    font-weight: 300;
    padding: 5px 10px;
}

.card-text h3,
.card-text p {
    margin: 0;
}